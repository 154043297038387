module.exports = {
    "tab" : [
        {
            name:'全部'
        },
        {
            name:'宣传片'
        },
        {
            name:'活动视频'
        },
        {
            name:'课堂实录'
        },
        {
            name:'优质课'
        },
        {
            name:'教师'
        },
        {
            name:'学生'
        },
        {
            name:'采访'
        },
        {
            name:'记录'
        },
        {
            name:'其他'
        }
    ]
}