<template>
    <div>
        <div v-if="videoData.fhash" class="test_two_box p-f-t">
            <video :id="'V'+videoData.vid" class="video-js">
                <source :src="videoData.fhash" type="application/x-mpegURL" />
            </video>
        </div>
        
        <div style="height: 190px;"></div>
        
        <div class="m-10">
            <div class="main-color m-b-10"><i class="fa fa-clock-o m-r-5"></i>发布时间：{{videoData.created_at}}</div>
            <h1 class="font-18">{{videoData.title}}</h1>
        </div>
        
        <div style="margin: 50px 40px 30px;">
            <divider><div style="font-size: 18px;">推荐视频</div></divider>
        </div>
        <div class="flex flex-jc-sb flex-w-w m-t-5 m-l-10 m-r-10 m-t-20">
            <div v-for="(item,index) in tuijianList" :key="index" @click="openVideo(item.vid)" class="box-shadow m-b-20 p-r" style="width:calc(50% - 5px);">
                <img src="/videos/fm.png" style="height: 200px;width: 100%;">
                <div class="text-align p-a w-100" style="top: 85px;">
                    <van-icon name="play-circle-o" size="40" color="#f5f5f5"/>
                </div>
                <div class="m-10">
                    <h3 class="font-14 l-h-30 text-ellipsis">{{item.title}}</h3>
                    <van-tag plain type="success" size="medium">{{Tab[item.type].name}}</van-tag>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import Tab from '../../../public/js/video-tab.js'
    
    export default {
        data() {
            return {
                videoData:{},
                tuijianList:[],
                videoObj:'',
                Tab:Tab.tab
            }
        },
        mounted() {
            if(!this.$route.query.vid){
                this.$toast.fail('获取视频失败')
                setTimeout(() => {
                    this.$router.go(-1);
                },2500)
                return false
            }
            this.getVideo(this.$route.query.vid)
            this.getVideoList()
        },
        methods:{
            openVideo(vid){
                window.location.href = '/video/details?vid='+vid
            },
            getVideo(vid){
                this.$api.video.getVideoById(vid).then((res) => {
                    this.videoData = res.data
                    if(res.data != ''){
                        setTimeout(() => {
                            this.videoEvent(this.videoData.vid)
                        },200)
                    }
                })
            },
            getVideoList(){
                this.$api.video.getVideoList().then((res) => {
                    this.tuijianList = res.data
                })
            },
            videoEvent(id){
                this.videoObj = this.$video('V'+id, {
                    //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                    controls: true,
                    poster: '/videos/cover.png', //封面
                    //自动播放属性,muted:静音播放
                    // autoplay: "muted",
                    //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                    // preload: "auto",
                    //设置视频播放器的显示宽度（以像素为单位）
                    width: "300",
                    //设置视频播放器的显示高度（以像素为单位）
                    height: "300"
                },
                function onPlayerReady() {
                    this.play();
                });
            }
        },
        beforeDestroy() {
            if(this.videoObj) this.videoObj.dispose()
        }
    }
</script>

<style>
    h1,h3{
        font-weight: normal;
    }
    
    .vjs-poster{
        background-size: auto;
    }
    
    .vjs-paused .vjs-big-play-button,
    .vjs-paused.vjs-has-started .vjs-big-play-button {
        display: block;
    } 
    .video-js .vjs-big-play-button{
        font-size: 2.5em;
        line-height: 2.3em;
        height: 2.5em;
        width: 2.5em;
        -webkit-border-radius: 2.5em;
        -moz-border-radius: 2.5em;
        border-radius: 2.5em;
        background-color: #73859f;
        background-color: rgba(115,133,159,.5);
        border-width: 0.15em;
        position: absolute;
        top: 33%;
        left: 40%;
    }
    /* 中间的播放箭头 */
    .vjs-big-play-button .vjs-icon-placeholder {
        font-size: 1.63em;
    }
    /* 加载圆圈 */
    .vjs-loading-spinner {
        font-size: 2.5em;
        width: 2em;
        height: 2em;
        border-radius: 1em;
        margin-top: -1em;
        margin-left: -1.5em;
    }
</style>
